import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
`;

export const Overlay = styled.div<{noWrap?: boolean}>`
  max-width: calc(100vw - 30px);
  pointer-events: none;
  position: absolute;
  white-space: ${({noWrap}) => (noWrap ? 'nowrap' : 'pre-line')};
`;

export const TooltipChildren = styled.div``;
