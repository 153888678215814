import {useWindowDimensions} from '@hooks';
import {mobileAndTabletCheck} from '@utils/mobile';
import React, {useEffect, useRef, useState} from 'react';
import {Overlay, TooltipChildren, TooltipWrapper} from './Tooltip.styles';

interface TooltipProps {
  className?: string;
  overlay: React.ReactElement;
  noWrap?: boolean;
  leftOffset?: number;
  doNotStopClickPropagation?: boolean;
}

export const Tooltip: React.FC<TooltipProps> = props => {
  const {width: windowWidth, height: windowHeight} = useWindowDimensions();

  const {
    className,
    overlay,
    children,
    noWrap,
    leftOffset = 280,
    doNotStopClickPropagation,
  } = props;

  const targetRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const tooltipProps = {
    isVisible: false,
    posX: 0,
    posY: 0,
  };

  const [tooltip, setTooltip] = useState(tooltipProps);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(mobileAndTabletCheck());
  }, []);

  const handleTouchOut = (e: TouchEvent) => {
    const {current: el} = targetRef;
    el && !el.contains(e.target as Node) && handleMouseOut();
  };

  const handleMouseOut = () => {
    if (!targetRef.current) {
      return;
    }
    setTooltip(tooltipProps);
    targetRef.current.removeEventListener('mouseleave', handleMouseOut);
    document.removeEventListener('touchstart', handleTouchOut);
  };

  const handlePointerEnter = (e: PointerEvent) => {
    e.stopPropagation();

    if (!tooltipRef.current || !targetRef.current) {
      return;
    }

    let leftPos = 10;
    let topPos = 10;

    const {bottom, height, left, width} = targetRef.current.getBoundingClientRect();
    const targetX = left + width / 2;

    if (document.documentElement.clientWidth - targetX > tooltipRef.current.clientWidth) {
      leftPos = width / 2;
    } else {
      leftPos = width - tooltipRef.current.clientWidth;
    }

    if (leftPos < 0) {
      leftPos = 0;
    } else if (leftPos + tooltipRef.current.clientWidth > windowWidth!) {
      leftPos = windowWidth! - tooltipRef.current.clientWidth;
    }

    if (topPos < 0) {
      topPos = 0;
    } else if (topPos + tooltipRef.current.clientHeight > windowHeight!) {
      topPos = windowHeight! - tooltipRef.current.clientHeight;
    }

    if (
      document.documentElement.clientHeight - bottom >=
      tooltipRef.current.clientHeight
    ) {
      topPos = height + 5;
    } else {
      topPos = -tooltipRef.current.clientHeight;
    }

    const mobileLeft = windowWidth! / 2 - left - tooltipRef.current.clientWidth / 2;

    setTooltip({
      isVisible: true,
      posX: isMobile ? mobileLeft : leftPos + 100,
      posY: topPos,
    });

    targetRef.current.addEventListener('mouseleave', handleMouseOut);
    document.addEventListener('touchstart', handleTouchOut);
  };

  useEffect(() => {
    const element = targetRef.current;

    if (element && !tooltip.isVisible) {
      const stopClickPropagation = (e: Event) => {
        e.stopPropagation();
      };

      element.addEventListener('pointerenter', handlePointerEnter);
      if (!doNotStopClickPropagation) {
        element.addEventListener('click', stopClickPropagation);
      }

      return () => {
        element.removeEventListener('pointerenter', handlePointerEnter);
        if (!doNotStopClickPropagation) {
          element.removeEventListener('click', stopClickPropagation);
        }
      };
    }
  }, [targetRef, tooltip.isVisible]);

  return (
    <TooltipWrapper style={{overflow: tooltip.isVisible ? 'initial' : 'hidden'}}>
      <Overlay
        className={className}
        ref={tooltipRef}
        style={{
          left: isMobile ? tooltip.posX : tooltip.posX - leftOffset,
          top: tooltip.posY,
          opacity: tooltip.isVisible ? 1 : 0,
          zIndex: tooltip.isVisible ? 99 : -20,
          transition: 'opacity .25s',
        }}
        noWrap={noWrap}>
        {overlay}
      </Overlay>
      <TooltipChildren ref={targetRef}>{children}</TooltipChildren>
    </TooltipWrapper>
  );
};
